import styled from "styled-components";
import Close from "../../../assets/icons/CloseModal.svg";
import AlertModalIcon from "../../../assets/icons/caution.svg";
import { ModalAlertWrapper } from "../ModalActions/ModalEditStyles";
import { useCollaboratorRedux } from "../../../redux/Collaborators";
import { useLocation } from "react-router-dom";

const AlertModal = ({ show, onHide, actionModal, message }: any) => {
  const { User } = useCollaboratorRedux();
  const location = useLocation();
  const onClose = () => {
    onHide(false);
  };

  console.log("User --->", User);

  return (
    <ModalAlertWrapper display={show}>
      <div className="modalWrapper">
        <div className="close">
          <div className="title">
            <img src={AlertModalIcon} className="image" alt="AlertModalIcon" />
            ¡Importante!
          </div>
          <div className="closeModal" onClick={(e) => onClose()}>
            <img src={Close} alt="close" style={{ height: "16px" }} />
          </div>
        </div>
        <ContainerModalText>
          <div className="textContainer">
            Estás a punto de {message} la descripción de
            {(!location.pathname.includes('Evaluacion')) && (User.role === 'Evaluador' || User.role === 'Admin RH' || User.role === 'Admin TI')
              ? 'objetivos para tu colaborador'
              : 'tu objetivo'}
            .
            <br />
            <br />
            ¿Deseas continuar con esta acción?
          </div>
        </ContainerModalText>
        <FooterModal>
          <ButtonModalSave
            onClick={() => {
              actionModal();
              onClose();
            }}
          >
            Continuar
          </ButtonModalSave>
          <ButtonModalCancel
            onClick={() => {
              onClose();
            }}
          >
            Cancelar
          </ButtonModalCancel>
        </FooterModal>
      </div>
    </ModalAlertWrapper>
  );
};

export default AlertModal;

export const InputObj = styled.textarea`
  width: 100%;
  height: 100%;
  border: none;
  outline: none;
  resize: none;
`;

export const ContainerModalText = styled.div`
  width: 100%;
  padding: 14px 21px;
  margin-top: 10px;

  .textContainer {
    font-size: 16px;
    color: #333335;
    font-weight: 400;
    padding-bottom: 5px;
  }

  .containerModal {
    width: 100%;
    background-color: #f8f8f8;
    border-radius: 4px;
    min-height: 100px;
    //height: auto;
    display: flex;
    justify-content: end;
    font-size: 14px;

    .txt {
      margin-top: 8px;
      padding: 8px;
    }

    .containerTxt {
      background-color: white;
      border-radius: 8px;
      font-weight: 700;
      color: black;
      font-size: 14px;
      padding: 8px;
      margin: 8px;
      margin-right: 24px;
      width: 83%;
    }
  }
`;

export const FooterModal = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  gap: 32px;
  padding: 14px 21px;
`;

export const ButtonModalSave = styled.div`
  background-color: #C9F2FF;
  border-radius: 8px;
  font-size: 14px;
  font-family: "Plus Jakarta Sans";
  font-weight: 700;
  color: #3B63A2;
  padding: 8px 24px;
  cursor: pointer;
  width: 100%;
  display: flex;
  justify-content: center;
  height: 40px;
  align-items: center;
  align-content: center;
`;

export const ButtonModalCancel = styled.div`
  background-color: #3B63A2;
  border-radius: 8px;
  font-size: 14px;
  font-family: "Plus Jakarta Sans";
  font-weight: 700;
  color: #FFFFFF;
  padding: 8px 24px;
  cursor: pointer;
  width: 100%;
  display: flex;
  height: 40px;
  align-items: center;
  align-content: center;
  justify-content: center;
`;
