/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-useless-concat */
import { ChangeEvent, useContext, useEffect, useState } from "react";
import Close from "../../../assets/icons/CloseModal.svg";
import { EvaluationContext, EvaluationContextType } from "../../../context/EvaluationContext";
import { useCollaboratorRedux } from "../../../redux/Collaborators";
import { useEvaluationRedux } from "../../../redux/Evaluations";
import { useObjetives } from "../../../redux/Objetives";
import { detectMonthPeriod } from "../utils/validationsSemester";
import { Date, Select, TextField } from "./InputsByTypeObjective";
import {
  BuildObjetive,
  ButtonWrapper,
  FormWrapper,
  ModalWrapper,
  SaveBtn,
} from "./ModalEditStyles";
import { disable } from "mixpanel-browser";
const mathExpression = [
  { text: "Selecciona opción", value: "0" },
  { text: "igual", value: "igual" },
  { text: "menor que", value: "menor que" },
  { text: "mayor que", value: "mayor que" },
  { text: "menor igual que", value: "menor igual que" },
  { text: "mayor igual que", value: "mayor igual que" },
];

interface Item {
  descripcion: {
    label: string;
  };
}

interface Props {
  data: Item[];
}


const value = {
  label: "Selecciona un objetivo",
  semester: 2,
  value: null,
};
const valueFuture = {
  label: "Selecciona una opcion",
  semester: 2,
  value: null,
};
interface ModalEditObjectiveProps {
  show: boolean;
  onHide: any;
  values: any;
  setValue: any;
  setData: any;
  data: any;
  indexObjetive: any;
  handleChange?: any;
  handleChangeDos?: any;
  defObj?: any;
}

const ModalEditObjective = ({
  show,
  onHide,
  values,
  setValue,
  setData,
  data,
  indexObjetive,
  handleChange,
  handleChangeDos,
  defObj,
}: ModalEditObjectiveProps) => {

  //** Context Values
  const {
    semester,
  } = useContext(EvaluationContext) as EvaluationContextType;

  const validarValores = (data: any[]) => {
    for (let i = 0; i < data.length; i++) {
      const currentValue = data[i].descripcion?.value;
      for (let j = i + 1; j < data.length; j++) {
        const nextValue = data[j].descripcion?.value;
        if ((currentValue?.toString() !=="0" && nextValue?.toString()!=="0") && currentValue?.toString() === nextValue?.toString() ) {
          setDisabled(false);
          return false;
        }
      }
    }
    setDisabled(true);
    return true;
  };

  //** Redux
  const { Evaluation, } = useEvaluationRedux();
  const { Collaborator } = useCollaboratorRedux();
  const { individualObjetives } = useObjetives();

  //** States
  const [disabled, setDisabled] = useState(false);
  const [writeInput, setWriteInput] = useState(false);



  const [validateDate, setValidateDate] = useState<boolean | undefined>(false)

  const findObjective = individualObjetives.find(
    (i) => i.generalObjetivesId === parseInt(values.value)
  );
  // console.log("adsadasda", semester);

  const onChangeTextField = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;


    if (name === "fGeneralObjetivesDate") {

      const validate = detectMonthPeriod(value, defObj ? semester : Evaluation.evaluationPeriod.evaluationPeriodNumberOfYear, defObj)


      if (validate === false) {
        setValidateDate(validate);
        setWriteInput(true);
        setValue({
          ...values,
          [name]: value,
        });
      } else {
        setValidateDate(validate);
      }



    }

    else {
      setWriteInput(true);
      setValue({
        ...values,
        [name]: value,
      });
    }

  };

  useEffect(() => {
    setValue({
      ...values,
      genderalObjetivesInfinitiveVerb:
        findObjective?.generalObjetivesInfinitiveVerb,
      generalObjetivesKpiConcept: findObjective?.generalObjetivesKpiConcept,
      generalObjetviesMathExpression:
        findObjective?.generalObjetviesMathExpression,
      generalObjetivesPreposition: findObjective?.generalObjetivesPreposition,
      generalObjetivesQuantityIndicator:
        findObjective?.generalObjetivesQuantityIndicator,
      generalObjetivesAdverb: findObjective?.generalObjetivesAdverb,
      fGeneralObjetivesDate: findObjective?.fGeneralObjetivesDate,
    });
  }, []);

  const validate = () => {
    if (
      values.genderalObjetivesInfinitiveVerb?.trim("") !== "" &&
      values.genderalObjetivesInfinitiveVerb !== undefined &&
      values.generalObjetivesKpiConcept?.trim("") !== "" &&
      values.generalObjetivesKpiConcept !== undefined &&
      values.generalObjetviesMathExpression !== "0" &&
      values.generalObjetviesMathExpression !== undefined &&
      values.generalObjetivesPreposition?.trim("") !== "" &&
      values.generalObjetivesPreposition !== undefined &&
      values.generalObjetivesQuantityIndicator?.trim("") !== "" &&
      values.generalObjetivesQuantityIndicator !== undefined &&
      values.generalObjetivesAdverb?.trim("") !== "" &&
      values.generalObjetivesAdverb !== undefined &&
      values.fGeneralObjetivesDate !== undefined &&
      values.fGeneralObjetivesDate?.trim("") !== ""
    ) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  };

  useEffect(() => {
    validate();
       validarValores(data);

  }, [values  ]);

  const closeModal = () => {
    onHide(!show);
    setDisabled(false);

    setValue({
      ...values,
      genderalObjetivesInfinitiveVerb: "",
      generalObjetivesKpiConcept: "",
      generalObjetviesMathExpression: "",
      generalObjetivesPreposition: "",
      generalObjetivesQuantityIndicator: "",
      generalObjetivesAdverb: "",
      fGeneralObjetivesDate: "",
    });
  };


  const onSubmit = () => {
    const newData = [...data];
    newData[indexObjetive].semestre = values.semester;
    newData[indexObjetive].descripcion = {
      label:
        values.genderalObjetivesInfinitiveVerb +
        " " +
        values.generalObjetivesKpiConcept +
        " " +
        values.generalObjetviesMathExpression +
        " " +
        values.generalObjetivesPreposition +
        " " +
        values.generalObjetivesQuantityIndicator +
        " " +
        values.generalObjetivesAdverb +
        " " +
        values.fGeneralObjetivesDate,
      semester: values.semester,
      value: values.value,
    };
    setData(newData);
    onHide(false);
  };

  const cancelObjective = (e: any) => {
    setDisabled(false);
    if (defObj) {

      e.preventDefault();

      // handleChangeDos(indexObjetive, "descripcion", { ...value, semester: Evaluation.evaluationPeriod.evaluationPeriodNumberOfYear === 1 ? 2 : 1 });
      handleChangeDos(indexObjetive, "descripcion", { ...value });
      closeModal();

    } else {

      handleChange(indexObjetive, "descripcion", { ...valueFuture, semester: Evaluation.evaluationPeriod.evaluationPeriodNumberOfYear === 1 ? 2 : 1 }, true);
      // handleChange(indexObjetive, "descripcion", { ...valueFuture }, true);
      closeModal();

    };
  }


  // console.log('Valor modificado del objetivo',value);


  return (
    <ModalWrapper display={show} activeAlert={validateDate || !disabled}>
      <div className="modalWrapper">
        <div className="close">
          <div className="title">Editar objetivo individual</div>
          <div className="closeModal" onClick={(e) => cancelObjective(e)}>
            <img src={Close} alt="close" style={{ height: "16px" }} />
          </div>
        </div>
        <FormWrapper>
          <div className="rowFirst">
            <TextField
              title="Verbo infinitivo"
              id="VerboInfinitivo"
              maxLength={150}
              name="genderalObjetivesInfinitiveVerb"
              placeholder="Entregar"
              onChange={onChangeTextField}
              value={values}
            />
            <TextField
              title="Concepto KPI"
              id="ConceptoKPI"
              maxLength={150}
              placeholder="Base de datos de proyecto regularización"
              name="generalObjetivesKpiConcept"
              onChange={onChangeTextField}
              value={values}
            />
          </div>
          <div className="rowSecond">
            <div className="selectWrapper">
              <Select
                title="Expresión matemática"
                id="Expresion"
                options={mathExpression}
                name="generalObjetviesMathExpression"
                onChange={onChangeTextField}
                value={values}
              />
            </div>
            <TextField
              title="Preposición"
              id="Preposición"
              maxLength={150}
              placeholder="El"
              name="generalObjetivesPreposition"
              onChange={onChangeTextField}
              value={values}
            />
            <TextField
              id="Indicador"
              title="Indicador"
              maxLength={150}
              name="generalObjetivesQuantityIndicator"
              onChange={onChangeTextField}
              value={values}
            />
          </div>
          <div className="rowThird">
            <TextField
              title="Adverbio"
              id="Adverbio"
              maxLength={150}
              name="generalObjetivesAdverb"
              onChange={onChangeTextField}
              value={values}
            />
            <Date
              title="Fecha"
              id="date"
              name={"fGeneralObjetivesDate"}
              onChange={onChangeTextField}
              value={values}
            />
          </div>
          <div className="rowFourth">
            <BuildObjetive>
              <div className="title">Oración</div>
              <div className="orationBuilded">
                {!writeInput
                  ? values.label
                  : values.genderalObjetivesInfinitiveVerb +
                  " " +
                  values.generalObjetivesKpiConcept +
                  " " +
                  values.generalObjetviesMathExpression +
                  " " +
                  values.generalObjetivesPreposition +
                  " " +
                  values.generalObjetivesQuantityIndicator +
                  " " +
                  values.generalObjetivesAdverb +
                  " " +
                  values.fGeneralObjetivesDate}
              </div>
            </BuildObjetive>
          </div>

          <ButtonWrapper>
            <button className="cancelBtn" onClick={(e) => cancelObjective(e)}>
              Cancelar
            </button>
            <SaveBtn className="saveBtn" disabledP={disabled} onClick={() => {
                if (disabled) {
                  setDisabled(false);
                    onSubmit();

                }
            }}>
              Guardar objetivos
            </SaveBtn>
          </ButtonWrapper>
        </FormWrapper>
        {validateDate &&
          (<div className="active-alert-modal">
            {defObj
              ? "No se permite el uso de una fecha correspondiente al período siguiente."
              : "No se permite el uso de una fecha correspondiente al período actual."}
               {disabled === false && "No se permite el Descripcines duplicadas."}
          </div>)
        }

{disabled === false &&
          (<div className="active-alert-modal">

          No se permiten descripciones duplicadas.
          </div>)
        }
      </div>
    </ModalWrapper>
  );
};

export default ModalEditObjective;
